<template>
  <div class="control-box">
    <div>
      <h2>Box</h2>
      <h3>{{ locked ? 'Locked' : 'Unlocked' }}</h3>
      <button @click="toggleLock" :class="{ 'gray-button': isButtonDisabled }">
        {{ locked ? 'Unlock' : 'Lock' }}
      </button>
      <h2>Phone</h2>

      <h3>{{ locked1 ? 'Locked' : 'Unlocked' }}</h3>
      <button @click="toggleLock1" :class="{ 'gray-button': isButtonDisabled }">
        {{ locked1 ? 'Unlock' : 'Lock' }}
      </button>
      <h2>Computer</h2>

      <h3>{{ locked2 ? 'Locked' : 'Unlocked' }}</h3>
      <button @click="toggleLock2" :class="{ 'gray-button': isButtonDisabled }">
        {{ locked2 ? 'Unlock' : 'Lock' }}
      </button>
    </div>

    <button @click="resetPin" class="reset">
      Reset Pin
    </button>
  </div>
</template>

<script>
export default {
  props: {
    locked: {
      type: Boolean,
      required: true,
    },
    locked1: {
      type: Boolean,
      required: true,
    },
    locked2: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isButtonDisabled: false,
    };
  },
  methods: {
    resetPin() {
      fetch(window.location.href + "api/deletepin", { credentials: 'include' }).then(location.reload());
    },
    toggleLock() {
      if (this.isButtonDisabled) {
        return;
      }
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);

      fetch(window.location.href + (this.locked ? "api/unlock" : "api/lock"), { credentials: 'include' });
    },
    toggleLock1() {
      if (this.isButtonDisabled) {
        return;
      }
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);

      fetch(window.location.href + (this.locked1 ? "api/phone/unlock" : "api/phone/lock"), { credentials: 'include' });
    },
    toggleLock2() {
      if (this.isButtonDisabled) {
        return;
      }
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);

      fetch(window.location.href + (this.locked2 ? "api/computer/unlock" : "api/computer/lock"), { credentials: 'include' });
    },
  },
};
</script>

<style scoped>
.control-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #fff;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 10px;
  border: 2px solid #6B95FF;
  border-radius: 5px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.gray-button {
  background-color: gray;
  cursor: not-allowed;
}
button {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #663399; /* Purplish-Black color */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.reset {
  background-color: #CA0000;
}
h3{
  background-color: #ff69b4;;
  padding: 5px;
  margin: auto;
  margin-bottom: 8px;
  border-radius: 10px;
}
</style>
