<template>
  <div id="app">
    <LoginBox :passwordSet="passwordSet" @loggedin="onLoggedin" v-if="!loggedin"/>
    <ControlBox v-if="loggedin" :locked="locked" :locked1="locked1" :locked2="locked2" />
  </div>
</template>

<script>
import LoginBox from './components/LoginBox.vue';
import ControlBox from './components/ControlBox.vue';
import { useCookies } from "vue3-cookies";

export default {
  name: 'App',
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    LoginBox,
    ControlBox
  },
  data() {
    return {
      passwordSet: true,
      loggedin: false,
      locked: false,
      locked1: false,
      locked2: false
    };
  },
  methods: {
    onLoggedin(value) {
      this.loggedin = value;
    },
    lockStatus() {
      fetch(window.location.href + 'api/status')
        .then(response => response.json())
        .then(data => {
          this.locked = data.message == "true";
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      fetch(window.location.href + 'api/phone/status')
        .then(response => response.json())
        .then(data => {
          this.locked1 = data.message == "true";
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      fetch(window.location.href + 'api/computer/status')
        .then(response => response.json())
        .then(data => {
          this.locked2 = data.message == "true";
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      setTimeout(() => {
        this.lockStatus();
      }, 1000);
    }
  },
  mounted() {
    this.lockStatus();

    const loginCookie = this.cookies.get('login');
    if (loginCookie === 'true') {
      this.onLoggedin(true);
    }

    fetch(window.location.href + 'api/passwordstatus')
      .then(response => response.json())
      .then(data => {
        this.passwordSet = data.message;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
};
</script>

<style>
body{
  background: linear-gradient(to right, #CAA1F5, #6B95FF);

}
#app {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0;
  z-index: -1;
}
@keyframes gradientAnimation {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
</style>
